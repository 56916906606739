<template>
  <div class="container-fluid">
      <b-form class="row" @submit.prevent="save()">
      <div class="col-12">
        <h5>{{ match.user_a_nick }} vs {{ match.user_b_nick }}</h5>
        <hr>
      </div>
      <div class="form-group col-6">
        <label for="local_goals">Local - <strong>{{ match.user_a_nick }}</strong></label>
        <b-form-input type="number" name="local_goals" autofocus id="local_goals" v-model="match.local_goal" v-validate="'required|numeric'" :state="validateState('local_goals')"></b-form-input>
        <span class="error-inputs">{{ errors.first('local_goals') }}</span>
      </div>
      <div class="form-group col-6">
        <label for="visit_goals">{{ $t('visitor') }} - <strong>{{ match.user_b_nick }}</strong></label>
        <b-form-input type="number" name="visit_goals" v-model="match.visit_goal" v-validate="'required|numeric'" :state="validateState('visit_goals')"></b-form-input>
        <span class="error-inputs">{{ errors.first('visit_goals') }}</span>
      </div>
      <div class="form-group col-12">
        <label for="file">{{ $t('findImage') }}</label>
        <div class="custom-file" :class="validateState('file') ? 'is-valid' : 'is-invalid'">
          <input type="file" ref="inputFile" class="custom-file-input" id="file" :lang="lang" @change="imagePreview($event)" v-validate="'required|image|ext:png,jpg,jpeg|size:1024'" accept=".png,.jpeg,.jpg" name="file" :data-vv-as="$t('image') | toLowerCase">
          <label class="custom-file-label" ref="lblFileName" for="file">{{ $t('findImage') }}</label>
        </div>
        <span class="error-inputs">{{ errors.first('file') }}</span>
        <img v-if="imgPath" :src="imgPath" alt="Vista previa" class="img-fluid img-thumbnail mt-3">
      </div>
      <div class="col-12">
        <b-button type="submit" variant="primary">{{ $t('save') }}</b-button>
      </div>
    </b-form>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      match: {},
      file: null,
      imgPath: null,
      matchId: this.$route.params.matchId
    }
  },
  created () {
    this.fetchData()
  },
  computed: {
    ...mapGetters([
      'console',
      'lang'
    ])
  },
  methods: {
    fetchData () {
      const path = `auth/${this.lang}/console/${this.console}/shop/bets/vs/${this.matchId}/upload-results`
      this.$axios.get(path).then(response => {
        this.match = response.data.data
      })
    },
    imagePreview (e) {
      const input = e.target  
      this.file = input.files[0]
      const _self = this
      if (input.files && input.files[0]) {
        _self.$refs.lblFileName.innerHTML = this.file.name
        var reader = new FileReader()
        reader.onload = function (e) {
          _self.imgPath = e.target.result
        }
        reader.readAsDataURL(input.files[0])
      } else {
        _self.file = null
        _self.$refs.lblFileName.innerHTML =  _self.$t('findImage')
      }
    },
    save () {
      this.$validator.validate().then(result => {
        if (result) {
          const path = `auth/${this.lang}/console/${this.console}/shop/bets/vs/${this.matchId}/upload-results`
          const payload = new FormData()
          payload.append('local_goal', this.match.local_goal)
          payload.append('visit_goal', this.match.visit_goal)
          if (this.file) {
            payload.append('image', this.file)
          }
          this.$axios.post(path, payload).then((response) => {
            this.$toastr.success(response.data.message, window.TITLE_SUCCESS)
            this.$router.push({ name: 'Vs', params: { lang: this.lang, console: this.console } })
          })
        } else {
          this.showValidationsErrors(this.errors.items);
        }
      })
    }
  }
}
</script>
